import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const LesCreations = () => (
  <Layout>
    <SEO title="Les créations" description="Les créations de la Compagnie Ailes de Cire sur Toulouse, Nice et La Rochelle. A Tire d'aile et les Oiseaux migrateurs." />
    <section id="creations-banner">
      <h1>LES CRÉATIONS</h1>
    </section>

    <section className="main container">
      <div className="row align-items-center creations-right-text" style={{marginBottom: `2rem`}}>
        <div className="col-md-5 col-sm-12 mb-3">
            <Image filename="les-oisseaux-migrateurs-square.jpg" alt="Les Oiseaux migrateurs, Clara Compain au bord de la fontaine" title="Les Oiseaux migrateurs" />
        </div>
        <div className="col-md-2 col-sm-12"></div>
        <div className="col-md-5 col-sm-12">
          <h2>Les Oiseaux migrateurs</h2>
          <p>Durée libre.</p>
          <p>Pour l'espace public.</p>
          <br />
          <Link to="/les-oiseaux-migrateurs/" className="btn btn-primary mt-5">En savoir plus</Link>
        </div>
      </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.046" height="136.58" viewBox="0 0 1921.046 136.58">
        <path id="Tracé_10" data-name="Tracé 10" d="M7960,1363c915.685-43.829,1394.665-76.561,1645.546-98.86C9881.02,1239.654,9880,1227.819,9880,1227.819" transform="translate(-7959.953 -1227.416)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
    <section className="main container">
      <div className="row align-items-center creations-left-text" style={{marginBottom: `2rem`}}>
        <div className="col-md-5 col-sm-12">
          <h2>À Tire d'aile</h2>
          <p>Durée 45 minutes.</p>
          <p>Pièce pour 3 interprètes.</p>
          <p>Pour l'espace public.</p>
          <Link to="/a-tire-d-aile/" className="btn btn-primary mt-5">En savoir plus</Link>
        </div>
        <div className="col-md-2 col-sm-12"></div>
        <div className="col-md-5 col-sm-12 mb-3">
            <Image filename="a-tire-daile-square.jpg" alt="À Tire d'aile, l'affiche" title="À Tire d'aile" />
        </div>
      </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.047" height="139.391" viewBox="0 0 1921.047 139.391">
      <path id="Tracé_11" data-name="Tracé 11" d="M7960,1227.819c915.685,44.744,1394.665,78.159,1645.546,100.923,275.473,25,274.453,37.077,274.453,37.077" transform="translate(-7959.952 -1226.821)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
    <section className="main container">
      <div className="row align-items-center creations-right-text" style={{marginBottom: `2rem`}}>
        <div className="col-md-5 col-sm-12 mb-3">
            <Image filename="carte-blanche-square.jpg" alt="Les pavés de la place Saint Sernin" title="Les pavés de la place Saint Sernin" />
        </div>
        <div className="col-md-2 col-sm-12"></div>
        <div className="col-md-5 col-sm-12">
          <h2>Carte Blanche</h2>
          <p>À partir de 16h00.</p>
          <p>Place Saint Sernin, à Toulouse.</p>
          <p>Du lundi au samedi.</p>
          <Link to="/carte-blanche/" className="btn btn-primary mt-5">En savoir plus</Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default LesCreations
